import { graphql } from "gatsby"
import React, { useContext } from "react"
import Background from "../components/atoms/background"
import SEO from "../components/seo"
import { CursorContext } from '../context/cursorContext'

export const query = graphql`
  query ContactPage {
    page: wpPage(slug: { eq: "contact" }) {
      id
      title
      slug
      background {
        color: presets
        custom
      }
      contactFields {
        sideText
        addressLeft {
          row1
          row2
          row3
          row4
          row5
          email
          googleMapsLink
        }
        addressRight {
          row1
          row2
          row3
          row4
          row5
          email
          googleMapsLink
        }
        text
      }
    }
  }
`

const ContactPage = props => {
  const { page } = props.data
  const { cursorConfig, setCursor } = useContext(CursorContext);

  if (page !== null) {
    console.log('moin')
    return (
      <div
        onMouseEnter={() => setCursor({
          ...cursorConfig,
          rotation: -30,
          scale: '1',
          color: 'var(--darkgrey)',
          shape: "default"
        })}
      >
        <SEO title="contact" />
        <Background preset={page.background.color} custom={page.background.custom} />
        <div
          className={"side-description"}
          dangerouslySetInnerHTML={{ __html: page.contactFields.sideText }}
        />
        <div className="container" id="contact">
          <div className="contact-content">
            <div className="address-container">
              <div className="address left">
                <p>{page.contactFields.addressLeft.row1}</p>
                <p>{page.contactFields.addressLeft.row2}</p>
                <p>{page.contactFields.addressLeft.row3}</p>
                <p>{page.contactFields.addressLeft.row4}</p>
                <p>{page.contactFields.addressLeft.row5}</p>
                <span>
                  <a
                    href={`mailto:${page.contactFields.addressLeft.email}`}
                    className="mail-link"
                  >
                    email
                  </a>
                </span>
                {/* <a
                  href={page.contactFields.addressLeft.googleMapsLink}
                  className="maps-link"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="maps"
                /> */}
              </div>
              <div className="address right">
                <p>{page.contactFields.addressRight.row1}</p>
                <p>{page.contactFields.addressRight.row2}</p>
                <p>{page.contactFields.addressRight.row3}</p>
                <p>{page.contactFields.addressRight.row4}</p>
                <p>{page.contactFields.addressRight.row5}</p>
                <span>
                  <a
                    href={`mailto:${page.contactFields.addressRight.email}`}
                    className="mail-link"
                  >
                    email
                  </a>
                </span>
                {/* <a
                  href={page.contactFields.addressRight.googleMapsLink}
                  className="maps-link"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="maps"
                /> */}
              </div>
            </div>
            <div
              className={"contact-text"}
              dangerouslySetInnerHTML={{ __html: page.contactFields.text }}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return <div />
  }
}

export default ContactPage
