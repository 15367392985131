/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect, useState} from "react"
import { Helmet } from "react-helmet"

function Background({ preset, custom }) {

    const [background, setBackground] = useState("white")

    useEffect(() => {
        setBackground(preset !== "custom" ? preset : custom)
    }, [preset, custom])

    return (
        <Helmet>
            <style>{`main, .container { background: ${background}; transition: 400ms ease;}`}</style>
        </Helmet>
    )
}

export default Background
